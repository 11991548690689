<template>
  <div class="main">
    <div class="wrapper">

      <div v-if="errorGeo">
        <v-sheet height="100vh">
          <div class="flex">
            <div class="flex flex-column">
              <div class="flex justify-center">
                <v-img max-width="600" :src="require('@/assets/img/prepare.svg')"></v-img>
              </div>
              <span class="text-center text-h5 green--text mt-3">
                Address not found! Please check your default address
              </span>
              <div class="flex justify-center my-3">
                <v-progress-circular indeterminate color="green"></v-progress-circular>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>
      <div v-if="isLoading">
        <v-sheet height="100vh">
          <div class="flex">
            <div class="flex flex-column mt-10">
              <div class="flex justify-center items-center">
                <div style=" width: 150px; height: 150px; ">
                  <v-img :src="require('@/assets/img/wrappingup.png')"></v-img>
                </div>
              </div>
              <span class="text-center text-h5 red--text mt-3">
                Wrapping up your order
              </span>
              <div class="flex justify-center my-3">
                <v-progress-circular indeterminate color="red"></v-progress-circular>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>
      <div v-else>
        <div v-if="isShoplink" class="cart__pages__delivery__shoplink__container"
          style="box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);">
          <div v-for="(n, i) in orderMethods" :key="i">
            <div
              :class="selectedOrderMethod == n ? 'cart__pages__delivery__shoplink__content_active' : 'cart__pages__delivery__shoplink__content_inactive'"
              @click="handleSelecteOrderMethod(n)">{{ n }}</div>
          </div>
        </div>
        <div class="cart__pages__sub__header__container">
          <div class="cart__pages__sub__header__left">
            <v-icon size="25" @click="navigateToProduct">mdi-arrow-left</v-icon>
            <div class="mb-1 ml-2" style="font-size: 18px;">User Details</div>
          </div>
          <div class="mt-1" style="color: #F85757; cursor: pointer;" @click="toUsers">Update</div>

        </div>
        <v-divider></v-divider>
        <div @click="toUsers" v-if="this.franchise.v2_qr_customer_input" class="cart__pages__user_detail__container">
          <h4>{{ selectedUser.name || "" }}</h4>
          <div class="gray--text">{{ selectedUser.phone || "" }}</div>
          <div class="gray--text">{{ selectedUser.email || "" }}</div>
          <div class="gray--text">{{ selectedUser.address || "" }}</div>
        </div>
        <v-divider></v-divider>

        <div class="cart__pages__summary_title__container">
          <div style="font-size: 18px;">Order Summary</div>
          <div @click="navigateToProduct" style="color: #F85757; cursor: pointer;" class="flex justify-end align-end">Add
            Items</div>

        </div>
        <v-divider></v-divider>
        <div v-for="(v, i) in cart" :key="i">
          <div class="cart__pages__list_items__container">
            <div class="cart__pages__list_items_left">
              <div class="cart__pages__list_items_left_quantity">
                <h5 class="white--text">

                  x{{ v.quantity }}
                </h5>
              </div>
            </div>
            <div class="cart__pages__list_items_center">
              <v-row class="justify-space-between pr-4">
                <div style="font-weight: 600;">{{ v.name }}</div>
                <div style="font-weight: 600;">{{ showTotalPrice(v) }}</div>
              </v-row>

              <v-row v-if="v.variant[0] && v.price_type == 0" class="justify-space-between pr-4">
                <span class="flex mt-1" v-for="(values, indexs) in v.variant" :key="indexs">
                  <div class="grey--text" style="font-style: italic;">{{ showAmt(values) }}</div>
                </span>
              </v-row>
              <div class="mt-4" v-if="v.modifiers.length > 0">
                <v-row class="justify-space-between pr-4" v-for="(val, ind) in renderModifierWithQuantity(v.modifiers)"
                  :key="ind">
                  <div class="grey--text">{{ showMod(val) }}</div>
                  <div class="grey--text">{{ val.amount.toFixed(2) }}</div>
                </v-row>
              </div>


              <h5 class="font-weight-bold" v-if="v.remarks">
                Remarks:
              </h5>
              <h5 class="font-weight-bold blue-grey--text" v-if="v.remarks">
                {{ v.remarks }}
              </h5>
              <div style="color: #F85757;margin-left: -10px; margin-top: 10px; cursor: pointer;"
                @click="selectProduct(v, i)">Edit</div>
            </div>
            <div class="cart__pages__list_items_right" @click="remove(i)">Remove</div>

          </div>
          <v-divider></v-divider>
        </div>
        <!-- <v-row v-for="(v, i) in cart" :key="i" class="row-cart">
                 
                  <v-col cols="1" lg="9" @click="selectProduct(v, i)" class="cols-cart flex align-star mr-14 pl-7">
                    <v-btn color="grey" class="pa-1">
                      
                    </v-btn>

                  </v-col>
                  
                  
                </v-row> -->
        <!-- <v-divider class="my-3"></v-divider> -->
        <!-- <v-divider class="my-2" v-if="i < cart.length - 1"></v-divider> -->
        <!-- voucher -->

        <v-expansion-panels class="grey lighten-1 mt-5 rounded-lg">
          <v-expansion-panel :style="{ 'background-color': '#F85757' }">
            <v-expansion-panel-header color="white--text text--darken-3">
              Apply Vouchers
              <template v-slot:actions>
                <v-icon color="white"> mdi-plus </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="">
              <h4 class="white--text text--darken-3">
                Enter a voucher code to apply
              </h4>
              <v-text-field name="name" color="white" id="id" clearable v-model="voucherCode"></v-text-field>
              <div v-if="!loadVoucher">
                <p v-if="isAlert == true" :class="`font-weight-bold nunito ${alertStatus ? 'white--text' : 'black--text'
                  }`">
                  {{ alertMessage }}
                </p>
              </div>
              <div v-else>
                <v-progress-circular indeterminate color="white"></v-progress-circular>
              </div>
              <v-card-actions class="justify-end">
                <v-btn color="white darken-3" right text @click="checkVoucher">Apply</v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


        <v-divider></v-divider>

        <v-expansion-panels class="grey lighten-1 rounded-lg mt-4" v-if="selectedOrderMethod == 'Delivery' && isShoplink"
          :class="[franchise.showVoucher || franchise.showVoucher == undefined ? '' : 'mt-5']">
          <v-expansion-panel :style="{ 'background-color': '#F85757' }">
            <v-expansion-panel-header color="white--text text--darken-3">
              {{
                delyvaLoading == null
                ? "Delivery Options"
                : delyvaLoading == 1
                  ? "Waiting for Delivery..."
                  : "Oww... Seems like there is no delivery service near you."
              }}
              <span v-if="delyvaLoading == 1" class="ml-2">
                <v-progress-circular indeterminate size="20" color="grey"></v-progress-circular>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="">
              <div :class="$vuetify.breakpoint.mdAndUp ? 'flex' : ''">
                <v-row v-if="this.delyvaLoading == null" no-gutters>
                  <v-card class="mr-2 ma-1" v-for="(n, i) in ship" :color="selectedDelivery && selectedDelivery.name == n.name
                    ? 'grey white--text'
                    : 'white'
                    " :key="i" @click="shipping(n)">
                    <div class="flex flex-column ma-2" v-if="n.name != 'unavailable'">
                      <span class="text-body-2">
                        {{
                          n.name
                        }}
                      </span>
                      <span v-if="n.data">+ RM{{
                        currency(n.data.delivery_fee)
                      }}</span>
                    </div>
                  </v-card>
                </v-row>
              </div>
              <!-- </div> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="rounded-lg" elevation="0">
          <div class="pa-3 source black--text">


            <div class="flex justify-space-between px-3" v-if="voucher">
              <p class="font-weight-regular">
                Voucher
                {{ voucher.delivery ? "(Delivery)" : "(Item)" }}
              </p>
              <p class="lato font-weight-regular" v-if="voucher.deduct">
                - {{ currency(voucher.deduct) }}
              </p>
              <p class="lato font-weight-regular" v-if="voucher.discount">
                - {{ voucher.discount }}%
              </p>
              <p class="lato font-weight-regular" v-if="selectedDelivery != null && voucher.delivery">
                -
                {{
                  currency(
                    selectedDelivery.data.delivery_fee -
                    voucher.delivery
                  ) > 0
                  ? currency(voucher.delivery)
                  : currency(selectedDelivery.data.delivery_fee)
                }}
              </p>
            </div>
            <!-- <v-row no-gutters >
                  
                  <v-col cols="12" class="py-0"> -->
            <!-- <v-card class="rounded-lg" > -->

            <!-- </v-card> -->
            <!-- </v-col>
                </v-row> -->
            <div v-if="total" class="flex justify-space-between px-3 mt-4">
              <p v-if="inclusive.status" class="font-weight-regular">
                Subtotal (Include {{ renderSSTPercent(inclusive.taxes[0].amount) }}% SST )
              </p>
              <p class="font-weight-regular" v-else>Subtotal</p>
              <p class="lato font-weight-regular">
                {{ currency(total) }}
              </p>
            </div>

            <div v-if="!isShoplink">
              <div class="flex justify-space-between px-3">
                <p class="font-weight-regular">Service Charge ({{ this.calculateServiceCharge(total).fee }}%)</p>
                <p class="lato font-weight-regular">
                  +
                  {{ currency(this.calculateServiceCharge(total).total) }}
                </p>
              </div>
            </div>
            <div v-if="!inclusive.status">
              <div class="flex justify-space-between px-3" v-if="currency(taxed).value !== 0">
                <p class="font-weight-regular">SST Tax ({{ parseInt(taxed) }}%)</p>
                <p class="lato font-weight-regular">
                  +
                  {{ currency(total).multiply(taxed).divide(100) }}
                </p>
              </div>
            </div>

            <div class="flex px-3" v-if="selectedDelivery != null && isShoplink">
              <p class="font-weight-regular">Delivery :</p>
              <div class="flex justify-space-between" v-if="selectedDelivery.provider &&
                selectedDelivery.provider !== ''
                ">
                <p class="lato font-weight-regular text-capitalize ml-2" v-if="selectedDelivery">
                  {{ selectedDelivery.name }}
                </p>
                <p class="lato font-weight-regular">
                  +
                  {{ currency(selectedDelivery.data.delivery_fee) }}
                </p>
              </div>
              <div v-else>
                <p class="lato text-capitalize ml-2 font-weight-regular" v-if="selectedDelivery">
                  {{ selectedDelivery.name }}
                </p>
              </div>
            </div>
            <div v-if="franchise.packaging_fees && isShoplink" class="flex justify-space-between px-3">
              <p class="font-weight-regular">Packaging Fees</p>
              <p class="lato font-weight-regular">
                +
                {{ currency(franchise.packaging_fees) }}
              </p>
            </div>
            <div class="flex justify-space-between">
              <h3 class="ml-2" style="font-weight: 800; ">Grand Total</h3>
              <h3 class="lato">
                RM
                {{ totaled() }}
              </h3>
            </div>
          </div>
        </v-card>

        <!-- <div class="pa-3 source black--text">
                <h3 class="pt-3">Choose Payment</h3>
                    <v-divider class="my-2" v-if="total"></v-divider>
                    <v-checkbox  label="Pay Online" @click="set(newUser)"> </v-checkbox>
                    <v-checkbox class="mt-n2" label="Pay at Counter" @click="set(newUser)"> </v-checkbox>  
                </div> -->


        <div class="cart_pages__footer_cta__container" v-if="isShoplink">
          <v-btn color="red" @click="checkout" class="ml-2" style="flex:1" dark large elevation="5" rounded-md
            :disabled="delyvaLoading == 1 || isCheckout">
            <v-progress-circular v-if="delyvaLoading == 1 || isCheckout" indeterminate
              color="white"></v-progress-circular>
            <span v-else>
              Checkout
            </span>
          </v-btn>
        </div>

        <div class="cart_pages__footer_cta__container" v-if="!isShoplink">
          <v-btn v-if="isPayAtCounter"
            :color="isPayAtCounterLoading ? 'red' : isPaymentOnline && !isPayAtCounter ? 'red' : 'grey'"
            class="btn_for_payment mr-2" style="flex:1" dark large elevation="5" rounded-md @click="pay_at_counter"
            :disabled="isPayAtCounterLoading">
            <v-progress-circular v-if="isPayAtCounterLoading" indeterminate color="white"></v-progress-circular>
            <span v-else>
              Pay at Counter
            </span>

          </v-btn>
          <v-dialog v-model="showModal" max-width="400" id="myModal">
            <v-card class="rounded-xl">
              <v-card-title class="title_for_modal">Sending Your Orders</v-card-title>
              <v-card-text>
                <div class="font-weight-bold black--text"
                  style="display: flex; justify-content: center; text-align: center; margin-top: 20px; font-size: 12px;">
                  <div style="width: 100px;">
                    <div class="content_img_modal">
                      <v-img :src="require('@/assets/img/phone.png')" alt="image 1"></v-img>
                    </div>
                    <p>Order Placed</p>
                  </div>
                  <div>
                    <v-img :src="require('@/assets/img/Advance.png')" alt="image 2" style="width: 100px;"></v-img>
                  </div>
                  <div style="width: 100px;">
                    <div class="content_img_modal">
                      <v-img :src="require('@/assets/img/slurp-logo-basic-01.png')" alt="image 3"></v-img>
                    </div>
                    <p>Order Received</p>
                  </div>
                </div>
              </v-card-text>

            </v-card>
          </v-dialog>

          <v-btn v-if="isPaymentOnline"
            :color="isPayOnlineLoading ? 'red' : isPaymentOnline && !isPayAtCounter ? 'red' : 'grey'" @click="checkout"
            class="ml-2" style="flex:1" dark large elevation="5" rounded-md :disabled="isCheckout">
            <v-progress-circular v-if="isPayOnlineLoading" indeterminate color="white"></v-progress-circular>
            <span v-else>
              Pay Online
            </span>

          </v-btn>
        </div>
        <v-dialog v-model="this.showInfoPaymentModal" scrollable :overlay="false" max-width="500px"
          transition="dialog-transition">
          <v-card>
            <v-card-title> Information </v-card-title>
            <v-card-text class="black--text">
              Your order cannot proceed due to inactive payment. Please contact person in charge
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="leaf" text @click="showInfoPaymentModal = false"> Close </v-btn>
              <v-btn color="leaf" size dark @click="goToHome()">
                Back to Home
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>


    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";


export default {
  name: "cart",


  data() {
    return {
      showModal: false,
      alertTop: [],
      isLoading: true,
      isCheckout: false,
      isShoplink: false,
      isPayOnlineLoading: false,
      isPayAtCounterLoading: false,
      isPaymentOnline: false,
      isPayAtCounter: false,
      isStaticQR: false,
      isSessionQR: false,
      alertMessage: "",
      alertStatus: false,
      errorGeo: false,
      isAlert: true,
      loadVoucher: false,
      selectedOrderMethod: "",
      orderMethods: [],
      is_payment_option_modal_show: false,
      selectedPaymentOption: '',//better_pay || billplizz
      total: null,
      selected: null,
      voucherCode: "",
      selectedDelivery: null,
      userLog: null,
      merchantLog: null,
      delyvaLoading: null,
      showInfoPaymentModal: false
    };
  },

  computed: {
    ...mapGetters({
      cart: "getCart",
      user: "getUsers",
      voucher: "getVoucher",
      ship: "getDelivery",
      franchise: "getFranchise",
      inclusive: "getInclusive",
      selectedOutlet: "getSelectedOutlet",
    }),

    currency() {
      return require("currency.js");
    },

    taxed() {
      var tax = "";
      if (this.franchise.outlets.length > 0) {
        tax =
          this.inclusive.taxes.length > 0
            ? this.inclusive.taxes[0].amount
            : "0";
      } else if (this.franchise.tax && this.franchise.tax !== "0") {
        tax = this.franchise.tax;
      }
      return tax
    },
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
  },

  watch: {
    voucherCode(newVoucherCode) {
      //  watch Voucher input spacing validation
      const cleanedVoucherCode = newVoucherCode.trim();
      if (cleanedVoucherCode.includes(" ") || cleanedVoucherCode.split(" ").length > 1) {
        this.alertMessage = "Voucher code cannot contain spaces or multiple words.";
        this.isAlert = true;
        this.alertStatus = false;
      } else {
        this.isAlert = false;
      }
    },
    ship() {
      if (this.selectedOrderMethod == "Delivery") {

        if (
          this.franchise.order_methods.includes("delivery") &&
          this.franchise.delivery_method.includes("delyvax")
        ) {
          if (this.ship.some((p) => p.provider == "delyvax") == false) {
            this.delyvaLoading = 1; //status loading
          } else {
            if (
              this.ship.some((p) => p.name == "unavailable") &&
              this.ship.length == 1
            ) {
              this.delyvaLoading = 2; //status delyva services unavailable
            } else {
              this.shipping(this.ship[0]);
              this.delyvaLoading = null;
            }
          }
        } else {
          if (
            this.selectedDelivery == null &&
            this.franchise.order_methods.length != 0 &&
            this.franchise.delivery_method.length != 0
          ) {
            this.shipping(this.ship[0]);
          }
        }
      }
    },
  },

  methods: {

    openModal() {
      this.showModal = true;
    },
    calculateServiceCharge(subtotal) {
      let service_charge_from_outlet = 0

      if (this.inclusive.status && !this.isShoplink) {
        let inclusive_percent = (this.renderSSTPercent(this.inclusive.taxes[0].amount) / 100) + 1
        const outlet = this.franchise.outlets.find(item => item.id == this.$route.params.outlet_id)
        service_charge_from_outlet = outlet.charges.length > 0 ? outlet.charges[0].amount : 0
        return {
          fee: service_charge_from_outlet,
          total: (subtotal / inclusive_percent) * (service_charge_from_outlet / 100),
        }
      }

      if (this.isSessionQR) {
        service_charge_from_outlet = this.franchise.outlets[0].charges && this.franchise.outlets[0].charges.length > 0 ? this.franchise.outlets[0].charges[0].amount : 0
      }
      if (this.isStaticQR && this.$route.params.outlet_id) {
        const outlet = this.franchise.outlets.find(item => item.id == this.$route.params.outlet_id)
        service_charge_from_outlet = outlet.charges.length > 0 ? outlet.charges[0].amount : 0
      }
      let service_charge_percent = service_charge_from_outlet > 0 ? service_charge_from_outlet / 100 : 0
      return {
        fee: service_charge_from_outlet,
        total: subtotal * service_charge_percent,
      }
    },
    closeModal() {
      this.showModal = false;
    },
    showTotalPrice(cartItem) {
      if (cartItem.variant.length > 0) {
        return (cartItem.variant[0].price * cartItem.quantity).toFixed(2)
      } else {
        return (parseFloat(cartItem.unit_price) * cartItem.quantity).toFixed(2)
      }
    },
    navigateToProduct() {

      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (outlet_id && table_id) { // STATIC QR
        this.$router.push({
          name: "Products",
          params: { child: this.$route.params.child, outlet_id: outlet_id, table_id: table_id, page: "products" },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionProducts",
          params: { session },
        });
      } else {
        // SHOP_LINK
        this.$router.push({
          name: "ShoplinkProducts",
          params: { child: this.$route.params.child, page: "products", },
        });
      }
    },
    onChangeOutlet(outlet_id) {
      const selectedOutlet = this.outletList.find(item => item.value == outlet_id)
      this.$store.dispatch("updateSO", selectedOutlet);
    },
    renderModifierWithQuantity(arr) {
      let modifiers = arr
      const totals = {};
      // Iterate over the data array
      modifiers.forEach(item => {
        const { id, name, mode, amount, } = item;

        // Check if the id exists in the totals object
        if (totals[id]) {

          // If it exists, increment the total
          totals[id].total++;
        } else {
          // If it doesn't exist, create a new entry
          totals[id] = { id, name, mode, amount, total: 1 };
        }
      });
      // Convert the totals object into an array
      const result = Object.values(totals);
      return result;

    },
    getOut(v) {
      console.log(v);
    },


    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },
    goToHome() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },
    handleSelecteOrderMethod(orderMethod) {
      if (orderMethod == "Pickup") {
        this.selectedDelivery = null
      } else {
        if (
          this.selectedDelivery == null &&
          this.franchise.order_methods.length != 0 &&
          this.franchise.delivery_method.length != 0
        ) {
          this.shipping(this.ship[0]);
        }
      }
      this.selectedOrderMethod = orderMethod
    },

    async shipping(i) {
      if (i.name != "unavailable") {
        //check whether the first array unavailable service or not
        if (
          this.franchise.min_order &&
          Object.keys(this.franchise.min_order).length > 0
        ) {
          var min =
            this.franchise.min_order[i.provider ? "delivery" : "pickup"];
          if (min) {
            if (min.app_to == "quantity") {
              let sum = this.cart
                .map((d) => d.quantity)
                .reduce((a, b) => a + b);
              if (min.amount !== "" && sum < min.amount) {
                let v = `Your cart does not meet minimum order of ${min.amount} items`;
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k == -1 ? this.alertTop.push(v) : (this.alertTop[k] = v);
              } else {
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k !== -1 ? this.alertTop.splice(k) : null;
              }
            } else {
              if (min.amount !== "" && this.total < min.amount) {
                let v = `Your cart does not meet minimum order of RM${currency(
                  min.amount
                )} net spend`;
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k == -1 ? this.alertTop.push(v) : (this.alertTop[k] = v);
              } else {
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k !== -1 ? this.alertTop.splice(k) : null;
              }
            }
          }
        }
        this.selectedDelivery = i;
      } else {
        this.delyvaLoading = 2;
      }
    },

    showMod(mod) {
      var prefix = mod.mode == 1 ? "+ " : mod.mode == 2 ? "- " : "",
        suffix = mod.mode == 3 ? "% " : "";
      var amt =
        mod.amount > 0 ? ` ${prefix} ${mod.amount.toFixed(2)}${suffix}` : "";
      return `${mod.name}${amt}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      return `${vars.name}`;
    },

    selectProduct(v, i) {
      let d = {
        value: v,
        index: i,
      };
      this.$store.dispatch('setEditCartIndex', i)
      this.$store.dispatch("editSP", d);
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (outlet_id && table_id) { // STATIC QR
        this.$router.push({
          name: "ProductsDetail",
          params: { child: this.$route.params.child, outlet_id: outlet_id, table_id: table_id, page: "product" },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionProductsDetail",
          params: { session },
        });
      } else {
        // SHOP_LINK
        this.$router.push({
          name: "ShoplinkProductsDetail",
          params: { child: this.$route.params.child, page: "products", },
        });
      }
    },

    add(i) {
      this.cart[i].quantity++;
      this.$store.dispatch("updateCart", this.cart);
    },

    async remove(i) {
      this.$store.dispatch("removeCart", i);
      this.total = await this.$store.dispatch("cartTotal");
      this.checkVoucher();
    },

    toProducts() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },

    toUsers() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (outlet_id && table_id) {
        this.$router.push({
          name: "StaticUser",
          params: { child: this.$route.params.child, outlet_id, table_id, page: "user"},
        })
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionUser",
          params: { session },
        });
      } else {
        // SHOP_LINK
        this.$router.push({
          name: "ShoplinkUser",
          params: { child: this.$route.params.child, page: "users"},
        });
      }
    },

    renderSSTPercent(percent) {
      // currently, we removed 2 digit of the value
      // example 11.00 will be display as 11, and 11.50 will be display 11
      let split_percent = percent.split('.')
      return split_percent[0] || 0
    },

    totaled() {
      var sum = this.total
      let discDelivery = 0.0;
      let calcDelivery;

      sum = currency(sum);
      if (this.franchise.outlets.length > 0 && this.inclusive.status == false) {
        var inc = sum.multiply(
          (this.inclusive.taxes.length > 0
            ? this.inclusive.taxes[0].amount
            : 0) / 100
        );
        sum = sum.add(inc.value);
      } else if (this.franchise.tax && this.franchise.tax !== "0") {
        var a = sum.multiply(this.franchise.tax / 100);
        sum = sum.add(a.value);
      }

      if (this.voucher) {
        if (this.voucher.discount) {
          sum = sum.multiply((100 - this.voucher.discount) / 100);
        }
        if (this.voucher.deduct) {
          sum = sum.subtract(this.voucher.deduct);
        }
        if (this.voucher.delivery) {
          discDelivery = this.voucher.delivery;
        }
      }

      // if (this.selectedDelivery) {
      //   // console.log("selected", this.selectedDelivery);
      //   if (
      //     this.selectedDelivery.provider == "speedy" ||
      //     this.selectedDelivery.provider == "lalamove" ||
      //     this.selectedDelivery.provider == "bungkusit"
      //   ) {
      //     // sum += this.selectedDelivery.data.delivery_fee;
      //     sum = sum.add(this.selectedDelivery.data.delivery_fee);
      //   } else if (this.selectedDelivery.provider == "own_delivery") {
      //     // sum += parseInt(this.selectedDelivery.data.delivery_fee);
      //     sum = sum.add(this.selectedDelivery.data.delivery_fee);
      //   }
      // }

      if (this.selectedDelivery && this.isShoplink) {
        if (this.selectedDelivery.provider == "delyvax") {
          calcDelivery = this.selectedDelivery.data.delivery_fee;
          if (discDelivery) {
            calcDelivery =
              this.selectedDelivery.data.delivery_fee - this.voucher.delivery;
            calcDelivery = calcDelivery < 0 ? 0.0 : calcDelivery;
          }
          sum = sum.add(calcDelivery);
        } else if (this.selectedDelivery.provider == "own_delivery") {
          calcDelivery = this.selectedDelivery.data.delivery_fee;
          if (discDelivery) {
            calcDelivery =
              this.selectedDelivery.data.delivery_fee - this.voucher.delivery;
            calcDelivery = calcDelivery < 0 ? 0.0 : calcDelivery;
          }
          sum = sum.add(this.selectedDelivery.data.delivery_fee);
        }
      }

      if (this.franchise.packaging_fees && this.isShoplink) {
        sum = sum.add(this.franchise.packaging_fees);
      }
      let service_charge_fee = this.calculateServiceCharge(this.total).total
      const final_totaled = sum.add(service_charge_fee)
      const final_result = Math.round(final_totaled.value * 20) / 20
      return currency(final_result);
    },

    async pay_at_counter() {
      this.showModal = true;
      this.isPayAtCounterLoading = true;
      let today = new Date();
      let uuid = await this.$store.dispatch("uuid");
      let defaultCustomer = {
        name: "Guest",
        address: "",
        address2: "",
        postcode: "",
        phone: "",
        coords: [],
        email: "",
        uuid: uuid,
      };
      let data = {
        customer: { ...this.selectedUser || defaultCustomer},
        cart: this.cart,
        qr_remark: this.franchise.qr_remark || false,
        pax: "",
      };
      if (this.isStaticQR) {
        data.table_id = String(this.franchise.table_selected[0].id)
      }
      if (this.isSessionQR) {
        data.table_id = this.franchise.table_id
      }
      let ls = data;
      ls.datetime = today.toLocaleString();
      ls.fire_id = this.franchise.fire_id;
      ls.totalOrder = this.totaled().value;
      this.$store.dispatch("saveOrder", ls);
      this.$store.dispatch("pay_at_counter", data).then(() => {
        this.isPayAtCounter = true;
        setTimeout(() => {
          this.$store.dispatch("updateCart", []);
          localStorage.removeItem("cart_list")
          this.showModal = false;
          this.isPayAtCounterLoading = false
          this.navigateToProduct()
        }, 2000)


      })
      // fetch(api("/guest/checkout"), data).then(function (response) {
      //   console.log('response', response)

      // });
    },

    async checkout() {
      this.isPayOnlineLoading = true
      // parsing data payment detail to backend
      let total = await this.$store.dispatch("cartTotal");
      let subtotal = this.currency(total).value;
      // Calculate taxValue persent %
      let taxed = parseFloat(this.taxed);
      let taxValue = this.currency(taxed).value;
      let taxAmount = this.currency(subtotal).multiply(taxed).divide(100).value;
      // Calculate serviceCharges
      let serviceCharges = this.currency(this.calculateServiceCharge(subtotal).total).value;

      let grandTotal = this.totaled().value;
      if (this.franchise.payments && this.franchise.payments.length > 0) {
        window.fbq("track", "Purchase");
        // check betterpay apikey and merchant id whether is empty,
        // need to display some information to the user
        const isBetterPayUnfilled = this.franchise.betterpay_status == "unfilled";
        if (this.cart.length == 0) {
          alert("Please add an item to the cart");
        } else if (this.franchise.payments[0] == "betterpay" && isBetterPayUnfilled) {
          this.showInfoPaymentModal = true
        } else {
          this.isCheckout = true;
          let uuid = await this.$store.dispatch("uuid");
          this.selectedUser.uuid = uuid;
          delete this.selectedUser.selected;

          let oGeo = this.franchise.outlets.find(
            (d) => d.id == this.selectedOutlet.value
          );

          let mercGeo = {
            address: oGeo ? oGeo.address : this.franchise.address,
            address2: oGeo ? oGeo.address2 : this.franchise.address2,
          };

          let fGeo = await this.$store.dispatch("fetchGeocode", mercGeo);
          var weight = this.cart.reduce(function (sum, item) {
            if (item.weight) {
              return (sum = sum + item.weight.converted * item.quantity);
            } else {
              return (sum = sum + 0.5 * item.quantity);
            }
          }, 0);

          let data = {
            customer: this.selectedUser,
            cart: this.cart,
            delivery: false,
            referral: "",
            notes: "",
            calculation: {
              subtotal,
              taxValue,
              taxAmount,
              serviceCharges,
              grandTotal,
            }
          };
          if (this.selectedDelivery && this.selectedDelivery.provider && this.isShoplink) {
            data.delivery = true;
            data.delivery_detail = {
              provider: this.selectedDelivery.provider,
              pickup_address1: `${fGeo.address}`,
              pickup_address2: `${fGeo.address2}`,
              dropoff_address1: `${this.selectedUser.address}`,
              dropoff_address2: `${this.selectedUser.address2}`,
              pickup_city: `${fGeo.city}`,
              pickup_state: `${fGeo.state}`,
              pickup_pc: `${oGeo ? oGeo.postcode : this.franchise.postcode}`,
              dropoff_city: `${this.userLog.city}`,
              dropoff_state: `${this.userLog.state}`,
              dropoff_pc: `${this.selectedUser.postcode}`,
              pickup_long: fGeo.longitude,
              pickup_lat: fGeo.latitude,
              dropoff_long: this.userLog.longitude || "",
              dropoff_lat: this.userLog.latitude || "",
              fire_id: this.franchise.fire_id,
              item_weight: weight,
              service: this.selectedDelivery.name,
              service_code: this.selectedDelivery.code,
              order_receipt_items: this.cart.map((d) => ({
                name: d.name,
                quantity: d.quantity,
              })),
            };
          }
          // console.log(data.delivery_detail, 'delivesry details')
          // if (this.franchise.payments && this.franchise.payments.length == 1) {
          //   data.paydirect = this.franchise.payments[0];
          // }
          var today = new Date();
          data.paydirect = this.franchise.payments[0]
          if (this.isStaticQR) {
            delete data.delivery
            delete data.notes
            delete data.referral
            data.qr_remark = this.franchise.qr_remark || false
            data.pax = ""
            data.table_id = String(this.franchise.table_selected[0].id)

          }
          var ls = data;
          ls.datetime = today.toLocaleString();
          ls.fire_id = this.franchise.fire_id;
          ls.totalOrder = this.totaled().value;
          this.$store.dispatch("saveOrder", ls);
          this.saveShopIdentifierForInvoice();
          if (this.franchise.payments[0] == 'betterpay') {
            if (this.isShoplink) {
              this.$store.dispatch("payBetterPay", data);
            } else {
              this.$store.dispatch("payBetterPayCheckout", data);
            }
          }
          if (this.franchise.payments[0] == 'billplz') {
            this.$store.dispatch("checkout", data);
            this.$store.dispatch("updateCart", []);
            localStorage.removeItem("cart_list")
          }
        }
      } else {
        alert('Payment did not configured yet ')
      }

    },

    saveShopIdentifierForInvoice() {
      const { table_id, outlet_id } = this.$route.params;
      let shopInfo = {
      }
      if (!table_id && !outlet_id) {
        shopInfo.shopType = "SHOP_LINK"
        shopInfo.code = this.$route.params.child
      }
      if (this.$route.params.session) {
        shopInfo.shopType = "SESSION_QR"
        shopInfo.session = this.$route.params.session
      }
      if (table_id && outlet_id) {
        shopInfo.code = this.$route.params.child
        shopInfo.table_id = table_id
        shopInfo.outlet_id = outlet_id
        shopInfo.shopType = "STATIC_QR"
      }

      localStorage.setItem('shop_info_invoice', JSON.stringify(shopInfo))
    },

    checkVoucher() {
      // voucher input spacing validation
      const cleanedVoucherCode = this.voucherCode.trim();
      if (cleanedVoucherCode.includes(" ") || cleanedVoucherCode.split(" ").length > 1) {
        window.alert("Voucher code cannot contains spaces or multiple words.");
        return;
      }
      this.loadVoucher = true;
      this.$store
        .dispatch("findVoucher", {
          voucher: this.voucherCode,
          total: this.total,
        })
        .then((r) => {
          if (!r.error) {
            r.min = r.min || 0;
            //check minimum spending
            if (this.total >= r.min) {
              this.loadVoucher = false;
              this.alertStatus = true;
              this.alertMessage = "Your voucher has been applied";
              this.isAlert = true;
            } else {
              this.loadVoucher = false;
              this.alertStatus = false;
              this.alertMessage =
                "Your subtotal does not meet minimum amount of RM" +
                currency(r.min).value;
              this.isAlert = true;
            }
          } else {
            this.loadVoucher = false;
            this.alertStatus = false;
            this.alertMessage = r.error;
            this.isAlert = true;
          }
        });
    },
    checkPaymentMethod() {
      this.isPayAtCounter = this.franchise.v2_qr_payment_method.includes('pay_at_counter')
      this.isPaymentOnline = this.franchise.v2_qr_payment_method.includes('online_payment')
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.checkPaymentMethod();
    const { table_id, outlet_id } = this.$route.params;

    if (!table_id && !outlet_id) {
      this.isShoplink = true;
    }
    if (this.$route.params.session) {
      this.isSessionQR = true
      this.isShoplink = false;
    }
    if (table_id && outlet_id) {
      this.isStaticQR = true
    }
    /* Ask user information if v2_qr_customer_input,
     otherwise skip the user form
     this is only for QR method
     */
    if (this.user.length == 0 && this.franchise.v2_qr_customer_input) {
      this.toUsers();
    }
    if (this.user.length == 0 && !this.franchise.v2_qr_customer_input && this.isShoplink) {
      this.toUsers();
    } 
    if (this.user.length > 0 || (this.user.length == 0 && !this.franchise.v2_qr_customer_input)) {
      this.total = await this.$store.dispatch("cartTotal");
      if (this.user.length > 0) {
        let c = await this.$store.dispatch("fetchGeocode", this.selectedUser);
        if (c == "ZERO_RESULTS") {
        let v = "Address not found. Please reconfirm your address";
        this.alertTop.push(v);
      }
      this.userLog = c;
      var deliver = [];
      }

      if (this.franchise.delivery_method.includes("[")) {
        deliver = JSON.parse(this.franchise.delivery_method);
      } else deliver = this.franchise.delivery_method;
      if (this.franchise.order_methods.length > 0) {
        this.selectedOrderMethod = this.franchise.order_methods[0] == "pickup" ? "Pickup" : "Delivery"
        if (this.franchise.order_methods.includes("pickup")) {
          this.orderMethods.push("Pickup")
        }
        if (this.franchise.order_methods.includes("delivery")) {
          this.orderMethods.push("Delivery")
        }
      }
      if (this.franchise.order_methods.includes("delivery")) {
        if (
          deliver.includes("own_delivery") ||
          this.franchise.delivery_method == "own_delivery"
        )
          this.$store.dispatch("fetchOwnDelivery");
        if (
          deliver.includes("delyvax") ||
          this.franchise.delivery_method == "delyvax"
        ) {
          this.$store.dispatch("fetchDelyvaFees", this.selectedUser);
        }
      }
    }
    this.isLoading = false;
  },
};
</script>
<style lang="scss">
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #F85757 !important;
  opacity: 0.8 !important;
}

.cart__pages__sub__header__container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cart__pages__sub__header__left {
    display: flex;
    align-items: center;
  }
}

.cart__pages__user_detail__container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.cart__pages__summary_title__container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__pages__list_items__container {
  display: flex;

  padding-left: 15px;

  .cart__pages__list_items_left {
    width: 10%;
    padding-top: 20px;
    padding-bottom: 20px;

    .cart__pages__list_items_left_quantity {
      width: 30px;
      height: 30px;
      background-color: grey;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .cart__pages__list_items_center {
    width: 70%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .cart__pages__list_items_right {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F85757;
    color: white;
    cursor: pointer;
  }

}

.cart__pages__delivery__shoplink__container {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  width: 100%;

  div {
    flex: 1;

    .cart__pages__delivery__shoplink__content_active {
      padding-bottom: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      color: #F85757;
      text-align: center;
      border-bottom: 3px #F85757 solid;



    }

    .cart__pages__delivery__shoplink__content_inactive {
      padding-bottom: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      // color: #F85757;
      // width: 45%;
      text-align: center;
      border-bottom: 3px white solid;
    }
  }
}

.cart_pages__footer_cta__container {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;


}

.title_for_modal {
  background-color: #F85757;
  color: #fff;
}

.content_img_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-left: 10px;
}

.btn_for_payment:hover {
  background-color: #F85757;
  color: white;
}
</style>
